import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
import { C, c, u } from "./convert-value-DxsB_h3x.js";
const LazyFormCheckGroup = lazy(
  () => import("./form-check-group-DxnbwgLC.js").then((module) => ({
    default: module.FormCheckGroup
  }))
);
function FormCheckGroup({
  uuid: _uuid,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFormCheckGroup, { ...props });
}
const useFormCheckGroupContent = () => {
  return useMemo(
    () => ({
      formCheckGroup: {
        component: FormCheckGroup
      }
    }),
    []
  );
};
export {
  C as CheckGroupContextProvider,
  LazyFormCheckGroup as FormCheckGroup,
  c as convertValue,
  u as useCheckGroup,
  useFormCheckGroupContent
};
